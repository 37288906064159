var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"8","xl":"8"}},[_c('br'),_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"title":"Visão geral","active":""}},[_c('b-card',[_c('h3',[_vm._v("Sobre este curso")]),_c('p',[_vm._v(_vm._s(_vm.curso.description))]),_c('hr',{attrs:{"size":"50"}}),_c('h2',[_vm._v("Detalhes do curso")]),_c('b-card-text',[_vm._v(" Nível de experiência: "+_vm._s(_vm.curso.level)+" ")]),_c('b-card-text',[_vm._v(" Idiomas: "+_vm._s(_vm.curso.language)+", ")]),_c('b-card-text',[_vm._v(" Legendas: "+_vm._s(_vm.curso.caption)+" ")]),_c('b-card-text',[_vm._v(" Aulas: 0000 ")])],1)],1),_c('b-tab',{attrs:{"title":"Professores"}},[_c('b-card',[_vm._v(" integrating... ")])],1)],1)],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"4","xl":"4"}},[(!_vm.isOnCart)?_c('b-button',{staticStyle:{"margin":"2px"},attrs:{"variant":"primary"},on:{"click":function($event){_vm.addToCart([{
          course_id: _vm.curso.id,
          congress_id: false,
          name: _vm.curso.title,
          amount: 1,
          value: parseFloat(_vm.curso.amount),
          img: _vm.curso.thumb
        }])}}},[_vm._v(" Adicionar ao carrinho ")]):_vm._e(),(_vm.isOnCart)?_c('b-button',{staticStyle:{"margin":"2px"},attrs:{"variant":"success","href":"/cursos"}},[_vm._v(" Continuar comprando ")]):_vm._e(),(_vm.isOnCart)?_c('b-button',{staticStyle:{"margin":"2px"},attrs:{"variant":"primary","href":"/pagamento"}},[_vm._v(" Ir para carrinho ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }